var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", width: "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn",
                      attrs: { text: "", disabled: !_vm.btnDisabledNewDelete }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-user" }),
              _vm._v(_vm._s(_vm.$t("New User")))
            ])
          ]),
          _c("v-card-text", [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("User ID") + " *",
                            placeholder: _vm.$t("Input the ID"),
                            clearable: ""
                          },
                          on: {
                            keypress: _vm.handleKeyUp,
                            keydown: _vm.clearTabindex
                          },
                          model: {
                            value: _vm.account,
                            callback: function($$v) {
                              _vm.account = $$v
                            },
                            expression: "account"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("Password") + " *",
                            placeholder: _vm.$t("Input the password"),
                            type: "password",
                            maxlength: _vm.max,
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt" }, [
                          _vm._v(_vm._s(_vm.$t("User Level")) + " *")
                        ]),
                        _c(
                          "v-radio-group",
                          {
                            attrs: { dense: "", "hide-details": "", row: "" },
                            model: {
                              value: _vm.accessLevel,
                              callback: function($$v) {
                                _vm.accessLevel = $$v
                              },
                              expression: "accessLevel"
                            }
                          },
                          _vm._l(_vm.accessLevelItems, function(item) {
                            return _c(
                              "div",
                              { key: item.level },
                              [
                                item.level === "1"
                                  ? _c("v-radio", {
                                      key: item.level,
                                      staticClass: "form-radio flat",
                                      staticStyle: { "margin-right": "15px" },
                                      attrs: {
                                        label: item.name,
                                        value: item.level,
                                        disabled: ""
                                      }
                                    })
                                  : _vm.userAccessLevel > item.level
                                  ? _c("v-radio", {
                                      key: item.level,
                                      staticClass: "form-radio flat",
                                      attrs: {
                                        label: item.name,
                                        value: item.level,
                                        disabled: ""
                                      }
                                    })
                                  : _vm.userAccessLevel <= item.level
                                  ? _c("v-radio", {
                                      key: item.level,
                                      staticClass: "form-radio flat",
                                      attrs: {
                                        label: item.name,
                                        value: item.level
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("Name") + " *",
                            placeholder: "" + _vm.$t("Input the name"),
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.name,
                            callback: function($$v) {
                              _vm.name = $$v
                            },
                            expression: "name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            label: _vm.$t("Email Address") + " *",
                            placeholder: _vm.$t("Input the email address"),
                            clearable: "",
                            outlined: "",
                            dense: "",
                            "hide-details": ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            [
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.clickSave }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }